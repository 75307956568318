var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("div", { staticClass: "Pricing" }, [
      _vm._m(0),
      _c("section", { staticClass: "plan2" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", { staticClass: "left" }),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "flex" }, [
              _c("span", [_vm._v("Plan II")]),
              _c("h2", [_vm._v(_vm._s(_vm.$t("pricing_consulting")))]),
              _vm._m(1)
            ])
          ])
        ])
      ]),
      _c("section", { staticClass: "service" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", [
            _c("h2", [_vm._v(_vm._s(_vm.$t("demo_request")))]),
            _vm._m(2),
            _c("a", { attrs: { href: "tel:02-713-5847" } }, [
              _vm._v(" 서비스 문의하기 ")
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "plan1" }, [
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/review3.png"), alt: "" }
            })
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("span", [_vm._v("Plan I")]),
            _c("h2", [_vm._v("인공지능 리뷰자동분석 SW 서비스")]),
            _c("div", [
              _c("p", [
                _vm._v(" 리뷰마인드의 SW 서비스는 충전 방식으로 제공됩니다. ")
              ]),
              _c("span", [
                _vm._v(" - 일정 금액 충전 후 "),
                _c("em", [_vm._v("(분석 리뷰 개수) * (건당 100원)")]),
                _vm._v("으로 총 금액을 산출하여 차감해 나가는 방식 ")
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "right flex" }, [
          _c("div", { staticClass: "img" }, [
            _c("img", {
              attrs: { src: require("@/assets/images/review3.png"), alt: "" }
            })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "txt" }, [
      _c("li", [
        _c("span", [_vm._v("보다 Customized 된 서비스를 원하시는 고객")]),
        _vm._v("을 위한 숙련된 "),
        _c("span", [_vm._v("리뷰마인드만의 고품질 컨설팅 서비스")]),
        _vm._v("를 제공합니다.")
      ]),
      _c("li", [
        _c("span", [
          _vm._v(
            " NLP 전문 데이터 사이언스 및 AI/NLP 모델 알고리즘 개발 관련 전문적인 컨설팅 "
          )
        ]),
        _vm._v("을 제공합니다.")
      ]),
      _c("li", [
        _c("span", [_vm._v("NLP용 AI 모델 및 시스템 개발의 모든 것")]),
        _vm._v(
          ", 리뷰마인드에 문의하세요. 전문상담사가 친절하게 응대해 드립니다!"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("* 리뷰마인드는 2022년 "),
      _c("span", [_vm._v("AI 바우처")]),
      _vm._v(" 지원사업 및 "),
      _c("span", [_vm._v("데이터 바우처")]),
      _vm._v(
        " 지원사업 공급기업에 선정되어, 해당 사업을 통한 서비스 진행도 가능합니다."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }